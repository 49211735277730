<template>
    <div>

        <section class="container-fluid dash-search-box">
                <div id="snackbar"></div>
            <div class="col-md-11 col-lg-10 mx-auto py-4">
                <div class="col-12  align-items-center mx-auto text-center bg-white round dash-search-box">
                    <form class="form-row align-items-center">
                        <div class="col-md-3 col-lg-4 my-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon2">
                                        <svg  width="18" height="20" viewBox="0 0 18 20">
                                            <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" transform="translate(-1)" />
                                        </svg>
                                    </div>
                                </div>
                                <input  @change="onChangeDate()" v-model="data.fromDate" type="date" id="todatepicker" class="form-control" placeholder="From Date: " aria-label="Input group example" aria-describedby="btnGroupAddon2">
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-4 my-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text" id="btnGroupAddon2">
                                        <svg  width="18" height="20" viewBox="0 0 18 20">
                                            <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" transform="translate(-1)" />
                                        </svg>
                                    </div>
                                </div>
                                <input  @change="onChangeDate()" v-model="data.toDate" type="date" id="fromdatepicker" class="form-control" placeholder="To Date: " aria-label="Input group example" aria-describedby="btnGroupAddon2">
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="d-flex justify-content-between align-items-center">
                                <!-- <div>
                                    <select id="inputState" class="form-control">
                                        <option selected>Custom</option>
                                        <option>...</option>
                                    </select>
                                </div> -->
                                <div class="downloadtype">
                                    <a :class="selectedType == 'XLSX' ? 'activeone' : ''" href="#" @click.prevent="selectedDownLoadType('XLSX')">
                                        <svg width="25" height="25" viewBox="0 0 30 28.652">
                                            <path id="Path_453" data-name="Path 453" d="M23.335,21.887H18.283l-3.446-4.874-3.621,4.874H6.142l6.3-7.917L7.354,7.046h5.125l2.381,3.765L17.4,7.046h5.215L17.283,13.97l6.052,7.917M30,28.652H0V0H30ZM2.882,25.77H27.113V2.884H2.882" fill="#08743b" fill-rule="evenodd" />
                                        </svg>
                                    </a>
                                    <a :class="selectedType == 'PDF' ? 'activeone' : ''" href="#" @click.prevent="selectedDownLoadType('PDF')">
                                        <svg id="Group_287" data-name="Group 287" width="25" height="25" viewBox="0 0 30.015 28.667">
                                            <path id="Path_454" data-name="Path 454" d="M30.015,28.667H0V0H30.015V28.667M2.884,25.783H27.128V2.886H2.884" fill="#ea4c3a" fill-rule="evenodd" />
                                            <path id="Path_455" data-name="Path 455" d="M15.4,4.408c.421.183.327.46.188,1.762a27.067,27.067,0,0,1-1.535,6.218,33.51,33.51,0,0,1-3.467,6.542,17.935,17.935,0,0,1-2.974,3.554,3.475,3.475,0,0,1-1.665.893c-.348.06-.506,0-.6-.175a1.225,1.225,0,0,1-.046-.819,2.55,2.55,0,0,1,.8-1.287,10.413,10.413,0,0,1,2.661-1.772,28.183,28.183,0,0,1,4.115-1.535,29.27,29.27,0,0,1,3.2-.745,23.692,23.692,0,0,1,2.39-.336,13.3,13.3,0,0,1,2.186,0,13.528,13.528,0,0,1,2.011.319,5.143,5.143,0,0,1,1.551.6,2.616,2.616,0,0,1,1.012,1.082,1.506,1.506,0,0,1,.033,1.229,1.316,1.316,0,0,1-.839.657,2.07,2.07,0,0,1-1.266,0,6.074,6.074,0,0,1-1.663-.893,15.558,15.558,0,0,1-2.057-1.768,36.1,36.1,0,0,1-2.691-3.015,21.139,21.139,0,0,1-2.028-3.028,11.287,11.287,0,0,1-.933-2.357,12.4,12.4,0,0,1-.427-2.12,7.442,7.442,0,0,1,.031-1.71,2.54,2.54,0,0,1,.317-1.054,1.1,1.1,0,0,1,.539-.41,1.259,1.259,0,0,1,.348-.073.64.64,0,0,1,.333,0A2.675,2.675,0,0,1,15.4,4.408Z" fill="rgba(0,0,0,0)" stroke="#ea4c3a" stroke-linejoin="round" stroke-width="1.57" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <input v-if="!status.filterButton" @click="onClickDownload()" type="button" class="btn btn-white download-btn mx-auto float-md-right" value="DOWNLOAD">
                                    <input v-if="status.filterButton" @click="onFilterData()" type="button" class="btn btn-white download-btn mx-auto float-md-right" value="GET REPORTS">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12 my-4">
                    <!-- <div class="col-12 l-booked box-shadow">
                        <div class="row box-shadow2">
                            <div class="col-md-6  ticket-booked">
                                <h3><strong>Ticket Booked</strong></h3>
                                <p>A constant monitor of all Tickets Booked</p>
                            </div>
                            <div class="col-md-6 ticket-booked">
                                <ul>
                                    <li> <a class="active" href="#">Today</a></li>
                                    <li> <a href="#">Week</a></li>
                                    <li> <a href="#">Month</a></li>
                                    <li> <a href="#">Year</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <b-card>
                                    <main-bar-chart chartId="main-chart-01" class="chart-wrapper" style="height:300px;margin-top:40px;" height="300"></main-bar-chart>                            
                                </b-card>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="col-md-12">
                    <!-- <c-table :table-data="items" :fields="fields" caption="<i class='fa fa-align-justify'></i> Simple Table"></c-table> -->
                    <b-card>
                      <!-- <b-form-group label-cols-sm="3" label="Filter" class="mb-0">
                          <b-input-group>
                              <b-form-input v-model="filter" placeholder="Type to Search" />
                          </b-input-group>
                      </b-form-group> -->
                      <!-- :filter="filter" -->
                        <b-table :dark="false" :hover="true" :striped="false" :bordered="false" :small="false" :fixed="false" responsive :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage">
                        <template class="text-center" slot="ViewSeatLayout" slot-scope="props">
                            <!-- <b-badge :variant="getBadge(data.item.status)">{{data.item.status}}</b-badge> -->
                            <!-- <a slot="uri" slot-scope="props" target="_blank" :href="props.row.uri" class="icon-eye"></a> -->
                            <a style="margin-left: 27%;" slot="uri" href="" @click.prevent="onShowSeatLayout(props.item, props.index, $event.target)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="57" height="32" viewBox="0 0 57 32">
                                <g id="Group_289" data-name="Group 289" transform="translate(-1766 -806)">
                                  <rect id="Rectangle_597" data-name="Rectangle 597" width="17" height="10" rx="2" transform="translate(1786 806)" fill="#d0d0d0"/>
                                  <path id="Path_456" data-name="Path 456" d="M1,0H12a1,1,0,0,1,1,1V2a1,1,0,0,1-1,1H1A1,1,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(1788 817)" fill="#d0d0d0"/>
                                  <rect id="Rectangle_598" data-name="Rectangle 598" width="17" height="10" rx="2" transform="translate(1766 806)" fill="#d0d0d0"/>
                                  <path id="Path_457" data-name="Path 457" d="M1,0H12a1,1,0,0,1,1,1V2a1,1,0,0,1-1,1H1A1,1,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(1768 817)" fill="#d0d0d0"/>
                                  <rect id="Rectangle_599" data-name="Rectangle 599" width="17" height="10" rx="2" transform="translate(1806 806)" fill="#d0d0d0"/>
                                  <path id="Path_458" data-name="Path 458" d="M1,0H12a1,1,0,0,1,1,1V2a1,1,0,0,1-1,1H1A1,1,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(1808 817)" fill="#d0d0d0"/>
                                  <rect id="Rectangle_600" data-name="Rectangle 600" width="17" height="10" rx="2" transform="translate(1786 824)" fill="#d0d0d0"/>
                                  <path id="Path_459" data-name="Path 459" d="M1,0H12a1,1,0,0,1,1,1V2a1,1,0,0,1-1,1H1A1,1,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(1788 835)" fill="#d0d0d0"/>
                                </g>
                              </svg>

                            </a>

                        </template>
                        </b-table>
                        <nav>
                            <b-pagination class="customPagination pt-3" align="center" :total-rows="items.length" :per-page="perPage" v-model="currentPage" >
                            </b-pagination>
                        </nav>
                    </b-card>
                </div>
            </div>
        </div>

         <!-- Info modal -->
        <b-modal id="seatLayoutModalInfo" @hide="resetModal" :title="'Seat Layout'" ok-only>

   
            <div class="container">
                <div class="seatSectHdr">
                    <div id="divSeats" class="seatIcnHdr">
                        <table border="0">
                            <tbody v-for="(seatLayout, index) in seatsObject.SeatLayouts" :key="index">
                                <tr>
                                    <td colspan="50">
                                    <div class="seat-catg screen screen-class">SCREEN</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colspan="50">
                                    <div class="seat-catg screen-class" style="height: 21px; margin-top: 10px;">CLASS</div>
                                    </td>
                                </tr>
                                <tr v-for="(seatArray,_index) in seatLayout.Seats" :key="_index">
                                    <td v-for="(seat, seatIndex) in seatArray" :key="seatIndex">
                                        <div v-if="seat.Status == 1" class="seat-area seat">
                                            <input type="checkbox" :id="seat.SeatName" :name="seat.SeatName">
                                            <label :for="seat.SeatName" class="seat-x">{{ seat.SeatName }}</label>
                                        </div>
                                        <div v-else-if="seat.Status == 2" class="seat-area seat">
                                            <input disabled type="checkbox" :id="seat.SeatName" :name="seat.SeatName">
                                            <label :for="seat.SeatName" class="seat-x">{{ seat.SeatName }}</label>
                                        </div>
                                        <div v-else class="seat-area seat">
                                            <!-- <input type="checkbox" :id="seat.SeatName" :name="seat.SeatName"> -->
                                            <label class="seat-x">&nbsp;</label>
                                        </div>
                                    </td>
                                    <!-- <td>
                                    <div class="seat-area seat"><input type="checkbox" id="B1" name=""><label for="B1" class="seat-x">B1</label></div>
                                    </td>
                                    <td>
                                    <div class="seat-area seat"><input type="checkbox" id="B2" name=""><label for="B2" class="seat-x">B2</label></div>
                                    </td>
                                    <td>
                                    <div class="seat-area seat"><input type="checkbox" id="B3" name=""><label for="B3" class="seat-x">B3</label></div>
                                    </td> -->
                                    
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import BarChart from "../charts/BarChart";
import MainBarChart from "../../Dashboard/MainBarChart";
import cTable from "../components/Table.vue";
import ReportService from "@/services/CinemaAndDistributorService/CinemaAndDistributorReportService";
import _ from "lodash";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import { pdfFillColor } from "@/helper/constant";

export default {
  data() {
    return {
      status: {
        filterButton: false
      },
      filter: "",
      data: {
        toDate: "",
        fromDate: ""
      },
      selectedType: "",
      currentPage: 1,
      perPage: 8,
      //   items: someData,
      //   fields: [
      //     { key: "username", label: "User", sortable: true },
      //     { key: "registered" },
      //     { key: "role" },
      //     { key: "status", sortable: true }
      //   ]
      items: [],

      fields: [
        { key: "Sr", label: "Sr. No." },
        { key: "CinemaName", label: "Cinema Name", sortable: true },
        { key: "Address", label: "Location", sortable: true },
        { key: "MovieName", label: "Title", sortable: true },
        { key: "Capacity", label: "Capacity", sortable: true },
        { key: "Threshold", label: "Threshold", sortable: true },
        { key: "NoOfTickets", label: "No Of Tickets", sortable: true },
        { key: "DaysToGO", label: "Days to Go", sortable: true },
        { key: "ShowDate", label: "Screening Date", sortable: true },
        { key: "ShowTime", label: "Screening Time", sortable: true },
        { key: "ScreeningStatus", label: "Screening Status", sortable: true },
        { key: "DCPStatus", label: "DCP Status", sortable: true },
        { key: "KeyPlayStatus", label: "Key Play Status" },
        { key: "KeyPlayStatus", label: "Key Play Status" },
        { key: "ViewSeatLayout", label: "View Seat Layout" }
      ],
      seatsObject: {
        SeatLayouts: []
      },
      payload: {
        cinemaLocation: "",
        cinemaName: "",
        movieName: "",
        fromDate: "",
        toDate: "",
        screenCategoryType: "",
        screening: "ALL_SCREENING"
      }
    };
  },
  components: {
    BarChart,
    MainBarChart,
    cTable
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  beforeMount() {
    // console.log("Store Data >>>>>>", this.$store.getters.reportFilterItem);
    console.log("this.secureStore.get()", this.secureStore.get("SCFIL"));
    // this.$store.dispatch("setReportFilter", payload);
    // this.secureStore.set("SCFIL", payload);
    // this.secureStore.set("SCFIL";
    if (this.secureStore.get("SCFIL")) {
      Object.assign(this.payload, this.secureStore.get("SCFIL"));
    }
    if (
      this.secureStore.get("TTURO") &&
      this.secureStore.get("TTURO") == "Distributor"
    ) {
      this.fields = [
        { key: "Sr", label: "Sr. No." },
        { key: "Distributor", label: "Distributor Name", sortable: true },

        { key: "MovieName", label: "Title", sortable: true },
        { key: "CinemaName", label: "Cinema Name", sortable: true },
        { key: "Address", label: "Location", sortable: true },
        { key: "ShowDate", label: "Screening Date", sortable: true },
        { key: "ShowTime", label: "Screening Time", sortable: true },
        { key: "Capacity", label: "Capacity", sortable: true },
        { key: "Threshold", label: "Threshold", sortable: true },
        { key: "NoOfTickets", label: "No Of Tickets", sortable: true },
        { key: "DaysToGO", label: "Days to Go", sortable: true },
        { key: "ScreeningStatus", label: "Screening Status", sortable: true },
        { key: "Revenue", label: "Revenue", sortable: true },
        { key: "FeaturedOnGogoSince", label: "Featured On GOGO Since" },
        { key: "AvailableTill", label: "Available Till" },
        { key: "ViewSeatLayout", label: "View Seat Layout" }
      ];
    }
    this.getReportListData(this.payload);
  },
  mounted: function() {},
  methods: {
    onChangeDate: function() {
      console.log("date>>>>>>>", this.data);
      this.status.filterButton = true;
      // let payload = {};
      // Object.assign(payload, this.payload);
      // if (this.data.fromDate !== "" && this.data.toDate !== "") {
      //   payload["fromDate"] = this.data.fromDate;
      //   payload["toDate"] = this.data.toDate;
      //   this.getReportListData(payload);
      // } else {
      //   this.showMessageSnackbar("Please Select From Date and To Date");
      // }
    },
    onFilterData: function() {
      let payload = {};
      Object.assign(payload, this.payload);
      if (this.data.fromDate !== "" && this.data.toDate !== "") {
        payload["fromDate"] = this.data.fromDate;
        payload["toDate"] = this.data.toDate;
        this.getReportListData(payload);
      } else {
        this.showMessageSnackbar("Please Select From Date and To Date");
      }
    },

    selectedDownLoadType: function(type) {
      if (type == "PDF") {
        console.log("check type PDF", type);
        this.selectedType = "PDF";
      } else if (type == "XLSX") {
        console.log("check type XLSX", type);
        this.selectedType = "XLSX";
      }
    },
    onClickDownload: function() {
      if (this.selectedType == "") {
        this.showMessageSnackbar("Please Select the Download type");
      } else if (this.selectedType == "PDF") {
        this.onDownloadPDF();
      } else if (this.selectedType == "XLSX") {
        this.onDownloadExcel();
      }
    },
    onDownloadPDF: async function() {
      if (this.items.length > 0) {
        let mainArray = [];
        this.items.map(x => {
          let tempArray = [];
          this.fields.map(y => {
            if (x.hasOwnProperty(y.key)) {
              tempArray.push(x[y.key]);
            }
          });
          mainArray.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);
        doc.addImage(imgData, "JPEG", 280, 0, 150, 100);
        doc.text(260, 100, `${WANASHAdashboardName.toUpperCase()} SCREENING REPORT`);
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.fields.map(x => x.label)],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("Show_Date_Report_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadExcel: function() {
      if (this.items.length > 0) {
        var animalWS = XLSX.utils.json_to_sheet(this.items);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, animalWS, "SheetOne"); // sheetAName is name of Worksheet
        //   XLSX.utils.book_append_sheet(wb, pokemonWS, "pokemons");
        XLSX.writeFile(
          wb,
          "Show_Date_Report_" + new Date().toDateString() + ".xlsx"
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getReportListData: function(data) {
      console.log("working here ", data);
      this.currentPage = 1;
      this.perPage = 8;
      this.items = [];
      ReportService.getReportFinalListData(data)
        .then(response => {
          const { data } = response;
          if (data.Status && data.Data.length > 0) {
            this.items = data.Data;
            this.status.filterButton = false;
          } else {
            this.status.filterButton = true;
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    onShowSeatLayout(item, index, button) {
      let payload = {
        CinemaScheduleID: item.ScheduleID,
        CategoryID: item.CategoryID,
        ShowType: ""
      };
      ReportService.getSeatLayoutData(payload)
        .then(response => {
          //   if (response.data.Status && response.data.Data.length > 0) {
          //     // this.items = response.data.Data;
          //   } else {
          //     this.showMessageSnackbar(response.data.Message);
          //   }
          const result = response;
          console.log("Get Seat layout", response);
          this.setSeatsData(item, result, button);
        })
        .catch(error => {
          this.showMessageSnackbar(error);
        });
    },
    setSeatsData(item, response, button) {
      const { data } = response;
      if (data.Status) {
        this.seatsObject = data;
        let seatArray = data.SeatLayouts;
        if (seatArray[0].Seats) {
          seatArray.map(x => {
            let count = x.Seats.filter(z => z.Status == 2);
            console.log(
              "plus !== count.lengthplus !== count.length",
              count.length
            );
            let plus = count.length;
            x.Seats.map((y, yindex) => {
              // parseInt(item.NoOfTickets) !== plus
              if (
                item.NoOfTickets != "0" &&
                parseInt(item.NoOfTickets) >= plus &&
                y.Status == 1
              ) {
                if (item.Capacity == item.NoOfTickets) {
                  y.Status = 2;
                } else if (
                  x.Seats[plus + yindex].Status &&
                  x.Seats[plus + yindex].Status !== 2
                ) {
                  console.log(
                    "x.Seats[plus + yindex].Status",
                    x.Seats[plus + yindex].Status
                  );

                  x.Seats[plus + yindex].Status = 2;
                } else {
                  console.log("else ", y.Status);

                  y.Status = 2;
                }
                // y.Status = 2;
                plus++;
              }
              y.SelectStatus = false;
              y.SelectStatusFlag = false;
              return y;
            });
            x.Seats = _.chunk(x.Seats, data.Column);
            return x;
          });
        }
        this.seatsObject["SeatLayouts"] = seatArray;
        this.$root.$emit("bv::show::modal", "seatLayoutModalInfo", button);
        console.log("seatArrayseatArrayseatArray", seatArray);
      } else {
        this.showMessageSnackbar(
          data.Message ||
            "We are unable to get the Seat Layout, Please Try after some time"
        );
      }
    },
    resetModal() {
      //   this.modalInfo.title = "";
      //   this.modalInfo.content = "";
    }
    // onShowSeatLayout(item, index, button) {
    //   console.log("CCheck ith details >>>", item, index, button);

    //   let payload = {
    //     CinemaScheduleID: item.ScheduleID,
    //     CategoryID: item.CategoryID,
    //     ShowType: ""
    //   };
    //   ReportService.getSeatLayoutData(payload)
    //     .then(response => {
    //       console.log("Get Seat layout", response);
    //       //   if (response.data.Status && response.data.Data.length > 0) {
    //       //     // this.items = response.data.Data;
    //       //   } else {
    //       //     this.showMessageSnackbar(response.data.Message);
    //       //   }
    //       let result = response;
    //       const { data } = response;
    //       if (data.Status) {
    //         this.seatsObject = data;
    //         let seatArray = data.SeatLayouts;
    //         if (seatArray[0].Seats) {
    //           seatArray.map(x => {
    //             x.Seats.map(y => {
    //               y.SelectStatus = false;
    //               y.SelectStatusFlag = false;
    //               return y;
    //             });
    //             x.Seats = _.chunk(x.Seats, data.Column);
    //             return x;
    //           });
    //         }
    //         this.seatsObject["SeatLayouts"] = seatArray;
    //         this.$root.$emit("bv::show::modal", "seatLayoutModalInfo", button);
    //         console.log("seatArrayseatArrayseatArray", seatArray);
    //       } else {
    //         this.showMessageSnackbar(
    //           data.Message ||
    //             "We are unable to get the Seat Layout, Please Try after some time"
    //         );
    //       }
    //     })
    //     .catch(error => {
    //       this.showMessageSnackbar(error);
    //     });
    // },
  },
  updated() {}
};
</script>

<style>
</style>

<style scoped>
/*seat layout*/
/* width */
.seat-layout-box ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
/* Track */
.seat-layout-box ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
.seat-layout-box ::-webkit-scrollbar-thumb {
  background: #7b7171;
  border-radius: 10px;
}
/* Handle on hover */
.seat-layout-box ::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, rgb(44, 2, 73) 15%, rgb(224, 0, 124));
}
.movie-sl-list .movie-format {
  color: #000;
  font-size: 10px;
  border-radius: 0.3rem;
  background-color: #fff;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 2px 5px;
}
.sl-list-box {
  color: #ddd !important;
}
.seatHdr.movieWrapHdr {
  top: -370px;
}
.movieWrapHdr {
  position: relative;
  top: -210px;
  z-index: 5;
  width: 1140px;
  margin: 0 auto;
}
.seat-layout-box {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.seatSectHdr {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}
.colA .head {
  font-size: 1rem;
  font-weight: 500;
}
.colA p {
  margin: 0;
}
.head {
  width: 100%;
}
.head .fa-arrow-left {
  color: #000;
  padding-right: 10px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.seatIcnHdr {
  margin: 0 auto;
  width: 90%;
  height: 300px;
  text-align: center;
  font-size: 0.7rem;
  overflow: auto;
}
.seatIcnHdr table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
}
.screen {
  color: #e5007d !important;
  text-align: center !important;
}
.seat-catg {
  line-height: 30px;
  text-align: left;
  font-size: 0.8rem;
  color: #d4d4d4;
}
.screen:before {
  border: 2px solid;
  content: "";
  background-color: !important;
  display: block;
  width: 70%;
  margin: 0 auto;
  border-radius: 10px;
}
.seatHd {
  width: 25px;
  line-height: 25px;
  color: #999;
}
.seat {
  width: 28px;
  line-height: 21px;
  margin: 5px 2px;
  cursor: pointer;
}
.seat-area input[type="checkbox"] {
  height: 0;
  width: 0;
  display: none;
}
.seat-area label {
  display: block;
  text-align: center;
  border-radius: 5px 5px 0 0;
  text-decoration: none;
  border: 1px solid #d5d5d5;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #999;
  font-weight: bold;
}
.seat-area input[type="checkbox"]:checked + label {
  background-color: #e5007d !important;
  color: #fff;
}
.seat-area input[type="checkbox"]:disabled + label {
  background-color: #ccc;
  color: #fff;
}
/*seat footer section S.A.S*/
.seatselDtl {
  background-color: #fff;
  border-top: 1px solid #ccc;
}
.seats {
  color: #b9b9b9;
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
  text-transform: uppercase;
}
.seats div {
  margin: 0 20px;
  display: inline-block;
}
.seats span {
  top: 5px;
  width: 25px;
  height: 20px;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  border: 1px solid #999;
  border-radius: 8px 8px 0 0;
}
.seat a.block,
.seats .block {
  color: #999;
  color: #fff;
  cursor: text;
  background-color: #ccc;
}
.seat a.slectd,
.seats .slectd {
  background-color: #e5007d;
  color: #ffff;
}
.dropdown-seat {
  position: absolute !important;
  top: 20px;
  right: 2%;
}
.sum .fa-location-arrow {
  color: #000;
}
.movie-dtail-modal button.close {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  position: absolute;
  right: -26px;
  top: -30px;
  z-index: 2000;
  opacity: 1;
  color: #ddd;
}
.movie-dtail-modal button.close:hover {
  opacity: 0.5 !important;
  color: #ddd !important;
}
.movie-dtail-modal .close {
  float: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
}
.modal-bg {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 111;
  background: rgba(0, 0, 0, 0.5);
}
/*SELECT NUMBER OF SEATS CSS*/
.numBox {
  height: 50px;
  width: 50px;
  font-size: 1.9rem;
  cursor: default;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
}
.numBox:hover {
  background: #e5007d;
  color: #fff;
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.selected {
  color: #fff;
  cursor: pointer;
  background: #e5007d;
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.price-list {
}
.city-link a:hover {
  color: grey;
  text-decoration: none;
}
.screen-class {
  color: #e5007d;
  text-align: center;
  font-weight: 600;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
</style>